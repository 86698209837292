import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CajasEsterilizacionService } from '../_services/cajas-esterilizacion.service';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { AuthenticationService } from '../_services/authentication.service';
import { DialogAsignacionCajaComponent } from '../dialog-asignacion-caja/dialog-asignacion-caja.component';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../_models/user.model';
import { MatPaginator } from '@angular/material/paginator';
import { ViewChild } from '@angular/core';

interface InstrumentistaInfo {
  matriculaInstrumentista?: number;
  fechaDesde?: String;
  fechaHasta?: String;
}

interface filtroCajasDisponibles {
  fechaCirugia?: String;
}

@Component({
  selector: 'app-cirugias-programadas',
  templateUrl: './cirugias-programadas.component.html',
  styleUrls: ['./cirugias-programadas.component.css']
})

export class CirugiasProgramadasComponent implements OnInit {
  displayedColumnasItems: string[] = ['fecha', 'horaInicio', 'horaFin', 'cirugiaProgramada', 'cirujano', 
                                      'instrumentista', 'sala', 'paciente', 'cajaAsignada', 'action'];
  loadingInstrumentistas = true;
  loadingCirugias = true;
  filtroBusquedaForm: UntypedFormGroup;
  filtrosCirugia: any = {};
  cirugiasProgramadas: any = {};
  instrumentadorSeleccionado: any;
  instrumentistas = null;
  hayCirugias = false;
  fechaActual: Date;
  dataSourceItems;
  datosOrdenados;
  datosAMostrar;
  rol: String;
  matricula: number;   // instrumentistas.unshift(nuevoElemento); de esta forma lo agrego al principio --- nuevoElemento = { 'todos': 'todos', 'matricula': null }
  isInstrumentista: boolean;
  filtros: InstrumentistaInfo = {};
  tienePermisos: boolean = false;
  instrumentista: String;
  noHayCirugias: boolean;
  noRegistrado: boolean = false;
  filtroCajas: filtroCajasDisponibles = {};
  errorCirugias: boolean = false;
  errorInstrumentistas: boolean = false;
  usuario;
  dataSource;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  cerroPopup = false;

  constructor(
    private internacionService: CajasEsterilizacionService,
    private formBuilder: UntypedFormBuilder,
    private cajasService: CajasEsterilizacionService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog
  ) { 

  }

  ngOnInit(): void {
    this.paginator._intl.itemsPerPageLabel="Elementos por página:";
    this.fechaActual = new Date();
    this.inicializar();
  }

  private buildFiltroBusquedaForm() {
    if (this.filtros.matriculaInstrumentista == -1) {
      this.filtroBusquedaForm = this.formBuilder.group({
        fechaDesde: [new Date()] ,
        fechaHasta: [null],
        matriculaInstrumentista: [-1],
      });
    } else {
      this.filtroBusquedaForm = this.formBuilder.group({
        fechaDesde: [new Date()] ,
        fechaHasta: [null],
        matriculaInstrumentista: [this.filtros.matriculaInstrumentista],
      });
    }

    this.buscarCirugiasProgramadas(this.filtros);
  }

  async inicializar() {
    let todos = {'nombre': 'TODOS', 'matricula': -1};
    await this.cajasService.getInstrumentadores().toPromise().then(
      (respuesta) => {
        if (respuesta.ok) {
          if (respuesta.elementos.length > 0) {
            this.instrumentistas = respuesta.elementos;
            this.instrumentistas.sort((a, b) => {
              const nombreA = a.nombre.toUpperCase();
              const nombreB = b.nombre.toUpperCase();
              if (nombreA < nombreB) {
                  return -1;
              }
              if (nombreA > nombreB) {
                  return 1;
              }
              return 0;
          });
            this.instrumentistas.unshift(todos);
            this.loadingInstrumentistas = false;
            this.getRol();
          }          
        } else {
          this.errorInstrumentistas = true;
          this.loadingInstrumentistas = false;
          this.loadingCirugias = false;
          console.log("hubo un error recuperando las cirugías programadas")
        }
          
      }, 
      (error) => {
        this.errorInstrumentistas = true;
          this.loadingInstrumentistas = false;
          this.loadingCirugias = false;
        console.log(error);
      }
    )
  }

  async buscarCirugiasProgramadas(filtros) {
    this.loadingCirugias = true;
    await this.cajasService.getCirugiasFiltro(filtros).toPromise().then(
      (respuesta) => {

        if (respuesta.ok) {
          this.datosOrdenados = this.ordenarCirugias(respuesta.elementos);
          this.dataSourceItems = new MatTableDataSource(this.datosOrdenados);
          this.dataSourceItems.paginator = this.paginator;
          this.hayCirugias = true;
          this.loadingCirugias = false;
          if (respuesta.elementos.length == 0) {
            this.noHayCirugias = true;
          } else {
              if (this.cerroPopup) {
                this.datosAMostrar = this.datosOrdenados.slice((this.dataSourceItems.paginator.pageIndex) * this.dataSourceItems.paginator.pageSize, (this.dataSourceItems.paginator.pageIndex + 1) * this.dataSourceItems.paginator.pageSize);
                this.cerroPopup = false;
              } else {
                this.dataSourceItems.paginator.pageIndex = 0;
                this.datosAMostrar = this.datosOrdenados.slice(0, 5);
              }
            
            this.noHayCirugias = false;
          }
        }
        else {
          this.loadingCirugias = false;
          this.errorCirugias = true;
          console.log("hubo un error", respuesta);
        }
      },
      (error) => {
        this.loadingCirugias = false;
        this.errorCirugias = true;
        console.log(error);
      }
    );
  }

  ordenarCirugiasPorFecha(cirugias: any[]): any[] {
    return [...cirugias].sort((a, b) => {
      const fechaA = a.fecha ? new Date(a.fecha.split('/').reverse().join('/')).getTime() : 0;
      const fechaB = b.fecha ? new Date(b.fecha.split('/').reverse().join('/')).getTime() : 0;
      return fechaA - fechaB;
    });
  }

  ordenarCirugias(cirugias: any[]): any[] {
    return [...cirugias].sort((cirugiaA, cirugiaB) => {
      const fechaHoraA = new Date(
        cirugiaA.fecha.split('/').reverse().join('-') + 'T' + cirugiaA.horaInicio
      );
      const fechaHoraB = new Date(
        cirugiaB.fecha.split('/').reverse().join('-') + 'T' + cirugiaB.horaInicio
      );
      if (fechaHoraA < fechaHoraB) {
        return -1;
      } else if (fechaHoraA > fechaHoraB) {
        return 1;
      } else {
        return 0;
      }
    });
  }  

  applyFilters(event) {
    this.filtros.fechaDesde = moment(event.fechaDesde, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('DD-MM-YYYY');
    if (event.fechaHasta != null) {
      this.filtros.fechaHasta = moment(event.fechaHasta, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('DD-MM-YYYY');
    } 
    this.filtros.matriculaInstrumentista = event.matriculaInstrumentista;
    this.buscarCirugiasProgramadas(this.filtros);
  }

  async getRol() {
    this.authenticationService.currentUser.subscribe(
      (resp) => {
        this.usuario = resp;
        this.isInstrumentista = resp.roles.some(rol => {
          return rol.nombre === 'Técnico' && rol.perfiles.some(perfil => perfil.nombre === 'Instrumentista');
        });
        if (this.isInstrumentista) {
          let rolTecnico;
          rolTecnico = resp.roles.find(function(rol) {
              return rol.nombre === 'Técnico';
          });
          this.matricula = rolTecnico.valorTipoID;
        }
        this.chequearPermisos();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  apareceEnTabla(matricula: number) {
    return this.instrumentistas.some(instrumentista => {
      if (instrumentista.matricula === matricula) {
        this.instrumentista = instrumentista.nombre;
      }
      return instrumentista.matricula === matricula;
    })
  }
  
  chequearPermisos() {
    if (this.isInstrumentista) {
      if (this.apareceEnTabla(this.matricula)) {
        this.filtros.matriculaInstrumentista = this.matricula;
      } else {
        this.filtros.matriculaInstrumentista = -1;
        this.noRegistrado = true;
      }
    } else {
      this.tienePermisos = true;
      this.filtros.matriculaInstrumentista = -1;
    }
    this.filtros.fechaDesde = moment().format('DD-MM-YYYY');
    this.filtros.fechaHasta = null;
    this.buildFiltroBusquedaForm();
  }

  async verCajasCirugia(cirugia) {
    cirugia.usuario = this.usuario.usuario;
    cirugia.reservaValida = this.anticipacionReservaValida(cirugia.fecha);
    this.dialog.open(DialogAsignacionCajaComponent, {
      width: '600px',
      data: cirugia,
    }).afterClosed().subscribe(
      (confirmado: boolean) => {
        this.cerroPopup = true;
        this.buscarCirugiasProgramadas(this.filtros);
      }
      
    );
  }

  pageChanged(event) {
    if (Math.abs(event.previousPageIndex - event.pageIndex) == 1) {
      if (event.previousPageIndex < event.pageIndex)
        this.datosAMostrar = this.datosOrdenados.slice((event.previousPageIndex + 1) * event.pageSize, (event.pageIndex + 1) * event.pageSize);
      else 
        this.datosAMostrar = this.datosOrdenados.slice((event.pageIndex) * event.pageSize, (event.previousPageIndex) * event.pageSize);
    } else {
      if (event.pageIndex == 0)
        this.datosAMostrar = this.datosOrdenados.slice(0 * event.pageSize, 1 * event.pageSize);
      else
        this.datosAMostrar = this.datosOrdenados.slice((event.pageIndex) * event.pageSize, (event.pageIndex + 1) * event.pageSize);
    }
  }

  anticipacionReservaValida(fecha: string): boolean {
    // Si la reserva es mayor a dos dias devuelve true
    const [day, month, year] = fecha.split('/').map(Number);
    const fechaCirugia = new Date(year, month - 1, day); 
    
    const hoy = new Date();
    hoy.setHours(0, 0, 0, 0); 
    
    const diferenciaDias = (fechaCirugia.getTime() - hoy.getTime()) / (1000 * 60 * 60 * 24);
  
    return diferenciaDias >= 2; 
  }

}
  
