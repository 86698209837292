<div class="row" id="firstRowBack" style="border: 0px solid red;">
  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
    <span style="float: right; margin-right: 3%;">
      <button mat-icon-button color="primary" (click)="volver()">
        <mat-icon aria-label="Example icon-button with a heart icon">keyboard_backspace</mat-icon> Volver
      </button>
    </span>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-card-header *ngIf="this.currentPatient">
    <mat-card-title
    >{{  currentPatient.apellido }}  {{ currentPatient.nombre  }}
</mat-card-title>

<mat-card-subtitle>
<span>
 
  <span > Documento </span>
  : {{ currentPatient.nroDocumento }}
</span>

</mat-card-subtitle>
<mat-card-subtitle>
 <span> Email: {{ currentPatient.email }}</span> |   
 <span> Telefono : {{ currentPatient.telefono }}</span>
  </mat-card-subtitle>

<mat-card-subtitle>
<span>Edad: {{ currentPatient.edad }}  </span> |
<span>{{ currentPatient.sexo }}</span>
|
<span>Nacimiento: {{ currentPatient.fechaNacimiento }}</span>
</mat-card-subtitle>
</mat-card-header>
  </div>
</div>

<mat-card>
<h3 style="text-align: center;">Triaje</h3>
<br>
<hr>
<br>
<div class="container-fluid">
 
  <div id="formularios">
    <mat-accordion>
        <mat-expansion-panel (opened)="this.impresionForm.invalid" >
         <mat-expansion-panel-header
           style="
             background: linear-gradient(
               180deg,
               rgba(255, 255, 255, 1) 95%,
               rgba(183, 198, 210, 0.65) 5%
             );
           "
         >
     <mat-panel-title> Derivacion de Servicios</mat-panel-title>
   </mat-expansion-panel-header>
   <br>
   <form [formGroup]="impresionForm">  
     <div class="row">
       <div class="col">
        <mat-form-field>
         <mat-select formControlName="servicio" placeholder="Servicios" required>
            <mat-option *ngFor="let serv of serviciosFilt" [value]="serv">
              {{serv.nombre}}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="impresionForm.controls['servicio'].invalid && submitted
          ">{{getErrorMessage('servicio')}}</mat-error>
      </mat-form-field>
      
      <span style="margin: 2%;"></span>
        <mat-form-field>
           <mat-select formControlName="respiratorio" placeholder="Respiratorio" required>
              <mat-option *ngFor="let resp of respiratorios" [value]="resp.value">
                {{resp.value}}
              </mat-option>
             
          </mat-select>
          <mat-error *ngIf="impresionForm.controls['respiratorio'].invalid && submitted
          ">{{getErrorMessage('respiratorio')}}</mat-error>
        </mat-form-field>
       <span style="margin: 2%;"></span>
       <mat-form-field>
       
        <mat-select formControlName="medico" placeholder="Medico derivado">
          <mat-option>
            <ngx-mat-select-search placeholderLabel="Buscar" 
            noEntriesFoundLabel="No se encontraron coincidencias" 
            [formControl]="filtermedCtrl">
          </ngx-mat-select-search>
          </mat-option>
           <mat-option *ngFor="let med of medFiltrados" [value]="med">
             {{med.apellido}} ({{med.nroMatricula}})
           </mat-option>
       </mat-select>
       
     </mat-form-field>
     <span style="margin: 2%;"></span>
     <mat-form-field>
       <mat-select formControlName="modoArribo" placeholder="Arribo">
          <mat-option *ngFor="let arribo of listaArribos" [value]="arribo">
            {{arribo.nombre}}
          </mat-option>
      </mat-select>
      
    </mat-form-field>
       <br>
       <mat-label><strong>Seleccione una prioridad * :</strong></mat-label>
       <br>
       
        <mat-radio-group formControlName="prioridadSeleccionada" required>
        
          <mat-radio-button class="example-margin" [value]="1">Rojo</mat-radio-button><br>
          <mat-radio-button class="example-margin" [value]="2"> Amarillo </mat-radio-button><br>
          <mat-radio-button class="example-margin"  [value]="3"> Verde </mat-radio-button>
          <mat-error *ngIf="impresionForm.controls['prioridadSeleccionada'].invalid && submitted
          ">{{getErrorMessage('prioridadSeleccionada')}}</mat-error>
      </mat-radio-group>
      
     </div>
   </div>
  </form>
  </mat-expansion-panel>
       <br>
       <hr>
       <br>
   
 <!-- Signos vitales -->
 <div class="content-form">
 <mat-expansion-panel>
  <mat-expansion-panel-header
    style="
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 95%,
        rgba(251, 230, 171, 0.65) 5%
      );
    "
  >
    <mat-panel-title> Signos Vitales y Score </mat-panel-title>
  </mat-expansion-panel-header>
  <app-signos-vitales [impresionFormHijo]="this.impresionForm"></app-signos-vitales>
 </mat-expansion-panel>
</div>
<br>
<hr>
<br>
<!-- Motivo de consulta -->
 <mat-expansion-panel>
  <mat-expansion-panel-header
    style="
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 95%,
        rgba(251, 230, 171, 0.65) 5%
      );
    "
  >
  
    <mat-panel-title> Motivo de consulta </mat-panel-title>
  </mat-expansion-panel-header>
  <br>
  <app-sintomas-paciente [signosFormHijo]="this.impresionForm"></app-sintomas-paciente>
 </mat-expansion-panel>


 
 <br>
</mat-accordion>

</div>
</div>
<!-- <div style="margin-left: 1%">
  <button type="submit" class="btn btn-success">Guardar</button>

</div> -->
<br>
<br>
<!-- <div> -->
<!-- <p class="alert  p-1 m-1 align-middle text-left" style="background-color: rgb(176, 193, 247); width: 300px;">
 PRIORIDAD SUGERIDA POR EL SISTEMA: {{this.impresionForm.value.subcatTriage.prioridad.id}}
</p> -->
<!-- <br>
<p><strong>Seleccione una prioridad:</strong></p>

<section>
  <mat-radio-group [(ngModel)]="prioridadSeleccionadaModel">
    <mat-radio-button class="example-margin" [value]="1">Rojo</mat-radio-button>
    <br>
    <mat-radio-button class="example-margin" [value]="2"> Amarillo </mat-radio-button>
    <br>
    <mat-radio-button class="example-margin"  [value]="3"> Verde </mat-radio-button>
  </mat-radio-group>
</section>


</div> -->
<br>
<hr>
<div class="d-flex justify-content-center" >
  <button  class="btn btn-success" type="submit" (click)="finalizarTriage() " [disabled]="this.impresionForm.invalid " [hidden]="this.loadingTriage">Fin triaje</button>
  <mat-spinner *ngIf = "this.loadingTriage" [diameter]="30" class="float-right align-middle ml-1"></mat-spinner>
</div>
</mat-card>
 











