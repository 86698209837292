<div *ngIf = "cargando; else cargado">
  <h6>Se está cargando la información, aguarde un momento</h6>
 <mat-spinner [diameter]="30"></mat-spinner>
</div>
<ng-template #cargado>  

  <div class="container-fluid" style="margin-top: 1%; margin-bottom: 1%;">
  <!--Filtros para el listado de triage disponibles-->
<mat-card>
  <div class="row" id="firstRowBack" style="border: 0px solid red;">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <span class="span-volver" style="float: right;">
        <button mat-icon-button color="primary" (click)="volver()">
          <mat-icon aria-label="Example icon-button with a heart icon">keyboard_backspace</mat-icon> Volver
        </button>
      </span>
    </div>
  </div>
    <div class="row" style="padding-bottom: 2%">
        
        <!-- <div class="col-3 col-sm-4 col-md-4 col-lg-3 col-xl-3 " style="padding-top: 2%; padding-left: 2%;"> -->
      
      
       <div class="container-fluid" style="margin-bottom: 1%;">

        
        <h5>Ingreso de pacientes</h5>
        <hr>
          <p>Seleccione un criterio de busqueda</p>    
                     <form [formGroup]="filtrotriageForm">
                     <div class="contenedor-filtros" >
                      <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-3">
                               <mat-form-field style="width: 100%;">
                                 <mat-label>Fecha desde *</mat-label>
                                 <input matInput formControlName="fechaDesde"  [matDatepicker]="pickerfechaDesde">
                                 <mat-datepicker-toggle matSuffix [for]="pickerfechaDesde" matTooltip="Seleccionar fecha" ></mat-datepicker-toggle>
                                 <mat-datepicker #pickerfechaDesde ></mat-datepicker>
                               </mat-form-field>
                         </div>
                         <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-3">
                               <mat-form-field style="width: 100%;">
                                 <mat-label>Fecha hasta *</mat-label>
                                 <input matInput formControlName="fechaHasta" [matDatepicker]="pickerfechaHasta">
                                 <mat-datepicker-toggle matSuffix [for]="pickerfechaHasta" matTooltip="Seleccionar fecha"></mat-datepicker-toggle>
                                 <mat-datepicker #pickerfechaHasta ></mat-datepicker>
                               </mat-form-field>
                           
                         </div>

                         <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-3">
                            <mat-form-field style="width: 100%;">
                              <mat-select formControlName="prioridad" placeholder="Seleccione una prioridad" style="width:100%">
                                <mat-option *ngFor="let p of listaPrioridades" [value] ="p.idPrioridad" >
                                  {{p.color}}
                                </mat-option>
                              </mat-select>
                              <mat-error *ngIf="filtrotriageForm.controls['prioridad'].invalid 
                                              && submitted">{{getErrorMessage('prioridad')}}</mat-error>
                            </mat-form-field>
                        </div>
                      </div>

                      <div class="contenedor-filtros">
                        <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-3">
                          <mat-form-field style="width: 100%;">
                            <mat-select formControlName="estado" placeholder="Seleccione un estado" style="width:100%">
                                <mat-option *ngFor="let t of estadoTriage" [value] ="t" >
                                    {{t.desc}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="filtrotriageForm.controls['estado'].invalid 
                                              && submitted">{{getErrorMessage('estado')}}</mat-error>
                          </mat-form-field>
                        </div>
                       
                        <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-3">
                          <mat-form-field style="width: 100%;">
                            <mat-select formControlName="medico" placeholder="Seleccione un profesional" style="width:100%">
                              <mat-option>
                                <ngx-mat-select-search placeholderLabel="Buscar" 
                                noEntriesFoundLabel="No se encontraron coincidencias" 
                                [formControl]="filtermedCtrl">
                                </ngx-mat-select-search>
                              </mat-option>
                              <mat-option *ngFor="let med of medFiltrados" [value] ="med" >
                                 {{med.nroMatricula == 'null' ? (med.apellido | toTitleCase) : (med.apellido | toTitleCase) + '(' + med.nroMatricula + ')' }}
                              </mat-option>
                            </mat-select>
                            <mat-error *ngIf="filtrotriageForm.controls['medico'].invalid 
                                              && submitted">{{getErrorMessage('medico')}}</mat-error>
                          </mat-form-field>
                        </div>
                        
                        <div class="col-xs-8 col-sm-8 col-md-4 col-lg-3 col-xl-3">
                           <button mat-raised-button 
                                   id="buscar-pacientes"
                                   color="primary" 
                                   (click)="cargarTriages(filtrotriageForm)" 
                                   style="border-radius: 20px; top: 8px; width: 100%;">
                               <mat-icon>search</mat-icon>
                               Buscar Triaje
                           </button>
                        </div>
                      </div>
                    
              <br>
              <!--Botones de busqueda-->  
                
                  <div class="botonCrearTriage">
                    <button mat-raised-button class= "ml-2" color="primary"  (click)="openDialogPacientes()" style="border-radius: 20px;" [disabled]="loading">Crear Triaje <mat-icon>add</mat-icon></button> 
                  </div>
                  
                   
                  
             </form>
             

                <!---------------------->
        </div>
        
      </div>
      <div class="d-flex justify-content-center"  *ngIf="loading">
        <mat-spinner [diameter]="60"></mat-spinner>   
      </div>
    <div>

      
      <div *ngIf="!existenTriages && !loading; else mostrarTriage">
        <div  style="text-align: center;">
           
          <h6 style="margin-left:2%">No se encontraron pacientes con triaje para el criterio buscado.</h6>
        </div> 
     
     </div>
     <!-- <div *ngIf="!this.hayTriageHoy ; else mostrarTriage">
      <div  style="padding-top: 15%;text-align: center;">
       
        <h6>No se encontraron pacientes con triage para el dia de hoy.</h6>
      </div> 
   
   </div> -->
   
     <ng-template #mostrarTriage>
          
      <div class="contenedor-cards-simil-tabla" *ngIf="!sinPacientes && !loading">

        <div class="card-simil-tabla" *ngFor="let triage of this.triages">
          <div class="contenedor-detalle-card" id="detalle-card">
            <div class="contenedor-atributos">
              <span class="span-nroDocumento">Nro.Documento:<strong> {{triage.paciente.nroDocumento}} </strong></span>
              <span class="span-apellido-nombre">Apellido y nombre:<strong> {{triage.paciente.apellido}} </strong></span>
              <span class="span-color">Color Triaje: <strong>{{triage.color ? triage.color.nombre : '-'}} </strong></span>
              <span class="span-respiratorio">Respiratorio: <strong>{{triage.respiratorio}} </strong></span>
              <span class="span-medico">Médico: <strong>{{triage.medDerivado.nombreCompleto ? triage.medDerivado.nombreCompleto : 'No asignado'}} </strong></span>
              <span class="span-servicio">Servicio: <strong>{{triage.servicio.nombre ? triage.servicio.nombre : '-'}} </strong></span>
              <span class="span-impresion-inicial">Impresión inicial: <strong>{{triage.subCategoria.nombreSubcat}} </strong></span>
              <span class="span-fecha-ingreso">Fecha ingreso: <strong>{{triage.fechaIngreso}} </strong> a las <strong> {{ triage.horaIngreso }} </strong></span>
              <span class="span-estado"> 
                <div class="center">
                  <div class="row">
                    <button        
                      mat-icon-button
                      class="estadoBoton"
                      color="primary" 
                      [disabled]="triage.estado.estado == 'ING'"
                      (click)="estadoAnterior(triage)"
                    >
                      <mat-icon>keyboard_arrow_left</mat-icon>
                    </button>
                             
                    <strong>{{triage.estado.desc}}</strong>
                              
                    <button
                      mat-icon-button
                      class="estadoBoton"
                      color="primary" 
                      (click)="estadoSiguiente(triage)"
                      [disabled]="triage.estado.estado == 'ESP'"
                    >
                      <mat-icon>keyboard_arrow_right</mat-icon>
                    </button>
                  </div>
                </div>
            </span>
            <span class="span-acciones">
              <span class="span-botones">
                <button mat-icon-button 
                matTooltip="Ver detalles"
                color="primary" 
                (click)="visualizarDatos(triage)"
                >
                  <mat-icon>visibility</mat-icon>
                </button>

                <button 
                  mat-icon-button 
                  [matMenuTriggerFor]="prioridadesMenu"
                  matTooltip="Modificar prioridad"
                  color="accent"
                  (click)="triageSeleccionado = triage"
                  >
                  <mat-icon>edit</mat-icon>
                </button>

                <button mat-icon-button 
                matTooltip="Eliminar Triage"
                color="warn" 
                (click)="eliminarTriage(triage)"
                >
                  <mat-icon>delete</mat-icon>
                </button>
              </span>
            </span>
            </div>
            
            <div class="contenedor-acciones">
              
            </div>
            
          </div>
        </div>

      </div>
      
      <mat-menu #prioridadesMenu="matMenu">
        <button
          mat-menu-item
          *ngFor="let prioridad of listaPrioridadesOriginal"
          (click)="cambiarPrioridad(triageSeleccionado, prioridad)"
        >
        <mat-icon [ngStyle]="{
          'color': prioridad.color === 'rojo' ? 'red' :
                   (prioridad.color === 'verde' ? 'green' :
                    (prioridad.color === 'amarillo' ? 'yellow' : 'black'))
          }">
          priority_high
        </mat-icon>
        <span>{{ prioridad.color }}</span>
        </button>
      </mat-menu>

      </ng-template>
      
    </div>
  </mat-card>
</div>
</ng-template>

